<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目月度收入统计</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :yearIsShow="true"
            :introductionType="'projectId'"
            :width="'100'"
            :type="'HistogramMonth'"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectIncomeId"
            v-model="sectionInfo.incomeYear"
            @change="onHistogramChange"
            @changeData="onIncomeDataChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="'项目名称：'"
            :type="'HistogramMonth'"
            :width="'400'"
            :introductionType="'projectId'"
            :clearable="true"
            :filterable="true"
            :list="projectList"
            :yearValue="sectionInfo.incomeYear"
            v-model="sectionInfo.projectIncomeId"
            @change="onHistogramChange"
            @changeData="onIncomeDataChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
      </div>
      <HistogramMonth :incomeList="incomeList" />
      <el-table border :data="incomeData">
        <el-table-column
          prop="contractAmountName"
          label="金额类型"
          align="center"
          fixed="left"
          min-width="120"
        ></el-table-column>
        <el-table-column prop="januaryAmount" min-width="120" label="一月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.januaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="februaryAmount" min-width="120" label="二月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.februaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="marchAmount" min-width="120" label="三月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.marchAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="aprilAmount" min-width="120" label="四月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.aprilAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="mayAmount" min-width="120" label="五月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.mayAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="juneAmount" min-width="120" label="六月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.juneAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="julyAmount" min-width="120" label="七月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.julyAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="augustAmount" min-width="120" label="八月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.augustAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="septemberAmount" min-width="120" label="九月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.septemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="octoberAmount" min-width="120" label="十月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.octoberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="novemberAmount" min-width="120" label="十一月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.novemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="decemberAmount" min-width="120" label="十二月金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.decemberAmount | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目月度收入统计.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    HistogramMonth: () => import('../histogramMonth.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      projectList: [],
      dictData: {
        yearList: [],
      },
      incomeList: [], // 项目收入统计
      incomeData: [],
      sectionInfo: {
        // 条件筛选 // 年份
        incomeYear: null,
        incomeDataYear: null,
        projectIncomeId: null,
        projectIncomeDataId: null,
      },
      titleYear: '统计时间',
      isDialog: false,
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onIncomeDataChange(arr) {
      this.incomeData = arr
    },
    onHistogramChange(arr) {
      this.incomeList = arr
    },
    async getType() {
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.incomeYear = dictVal
          this.sectionInfo.incomeDataYear = dictVal

          const queryMonthlyProjectIncomeStatistics =
            await this.$api.statistics.queryMonthlyProjectIncomeStatistics(`date=${dictVal}`)
          const { projectMonthlyIncomeStatisticsVo = {} } = queryMonthlyProjectIncomeStatistics.data
          const nameMapping = {
            invoicedAmount: '已开票金额',
            proceedsAmount: '已收款金额',
          }
          this.incomeList = Object.keys(projectMonthlyIncomeStatisticsVo)
            .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: nameMapping[key], // 根据键获取名称
              data: projectMonthlyIncomeStatisticsVo[key],
            }))
          this.incomeData = projectMonthlyIncomeStatisticsVo?.contractPaymentListVoList || []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      this.$api.project
        .getProjectInfoByStaffId()
        .then(res => {
          this.projectList = res.data?.projectNameRespList.map(v => ({
            id: v.id,
            dictName: v.projectName,
            dictVal: v.id,
          }))
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      // 统一处理每一列的值
      const calculateSum = (columnIndex, columnProperty) => {
        const values = data.map(item => Number(item[columnProperty]))
        // 判断是否所有值都是有效的数字
        if (values.every(value => !isNaN(value))) {
          return values.reduce((prev, curr) => {
            const value = Number(curr)
            return isNaN(value) ? prev : prev + value
          }, 0)
        } else {
          return '' // 如果有无效数字，返回空字符串
        }
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        // 判断是否需要统计值
        const shouldCalculate =
          (this.isBoss && index > 3 && index !== 6 && index !== 9) ||
          (!this.isBoss && index > 2 && index !== 5 && index !== 8)
        if (shouldCalculate) {
          const sum = calculateSum(index, column.property)
          sums[index] = sum === '' ? '' : Number(sum.toFixed(2)).toLocaleString() // 格式化为两位小数并加上千分位
        } else {
          sums[index] = '--' // 不需要统计的列显示 '--'
        }
      })
      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
